import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { remark } from "remark"
import remarkHtml from "remark-html"

import Layout from "../../components/Layout"

import useGetBrowserLanguage from "../../hooks/useGetBrowserLanguage"
import { safeNavigate } from "../../helpers/safeNavigate";
import findLangIndex from "../../helpers/findLangIndex";

import styles from "./industry.module.scss"

const Industry = ({ pageContext }) => {
  const language = useGetBrowserLanguage()
  const { slug } = pageContext;

  const { allMarkdownRemark: industriesData } = useStaticQuery(graphql`
    query IndustryTemplateQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(solutions.md)/" } }
      ) {
        edges {
          node {
            fileAbsolutePath
            frontmatter {
              industries {
                slug
                image
                content
              }
            }
          }
        }
      }
    }
  `)

  const remarkObj = useMemo(() => remark().use(remarkHtml), [])

  const langId = useMemo(
    () => { return findLangIndex(industriesData?.edges, "solutions/solutions.md", language); },
    [industriesData, language]
  );

  const currIndustry = industriesData?.edges?.[langId]?.node?.frontmatter?.industries?.find(item => item.slug === slug);
  if (!currIndustry) {
    safeNavigate("/404");
  }

  return (
      <Layout>
        {currIndustry ? (
            <div className={styles.mainBody}>
              <div className={styles.content}>
                <div className={styles.content__columns}>
                  <div
                      className={styles.content__columns__text}
                      dangerouslySetInnerHTML={{__html: remarkObj.processSync(currIndustry.content)}}
                  />
                  <div className={styles.content__columns__image}>
                    <img src={currIndustry.image} alt="" />
                  </div>
                </div>
              </div>
            </div>
        ) : <div />}
      </Layout>
  )
}

export default Industry
